import React, { Component, Fragment } from 'react';

import * as contentful from 'contentful';

const initialState = {
	selectCoordinates: () => {},
	loadPoints: () => {},
	loadGoogleMap: () => {},
	setSelectedOption: () => {},
	setStockists: () => {},
	setGooglePlace: () => {},
	setCurrentLocation: () => {},
	selectStockist: () =>{},
	stockists : [],
	noStockists : false,
	currentLocation : null,
	selectedOption : null,
	selectedStockist : null,
	loadingGooglePlace : false,
	selectedGooglePlace : null,
	google: null
};

export const FinderContext = React.createContext(initialState);

class FinderState extends Component {

	constructor(props) {
		super(props);
		this.state = initialState;
	}

	selectCoordinates = ({x, y}) => {
		this.setState({x, y})
	}

	loadPoints = (points) => {
		this.setState({allStockists: points});
	}

	loadGoogleMap = (map) => {
		this.setState({google: map});
	}

	setCurrentLocation = ({currentLocation}) => {
		this.setState({currentLocation});
	}

	setSelectedOption = (option) => {
		// console.log('FinderState set selectedOption,', option)
		this.setState({selectedOption : option});
	}

	setStockists = (items, noStockists) => {
		this.setState({stockists: items, noStockists: noStockists});
	}

	setGooglePlace = ({loadingGooglePlace, selectedGooglePlace}) => {
		this.setState({loadingGooglePlace, selectedGooglePlace});
	}

	selectStockist = ({selectedStockist}) => {
		this.setState({selectedStockist});
	}

	render() {
		const { children } = this.props;
		return (
		<FinderContext.Provider
			value={{
				...this.state,
				selectCoordinates: this.selectCoordinates,
				loadPoints: this.loadPoints,
				loadGoogleMap: this.loadGoogleMap,
				setLocale: this.setLocale,
				setSelectedOption: this.setSelectedOption,
				setStockists: this.setStockists,
				setGooglePlace: this.setGooglePlace,
				setCurrentLocation: this.setCurrentLocation,
				selectStockist: this.selectStockist
			}}
		>
		{children}
		</FinderContext.Provider>
	)}
}

export default FinderState;
