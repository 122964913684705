/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import FinderState from 'src/store/FinderState'

export const wrapRootElement = ({ element }) => {
  return (
    <FinderState>
      {element}
    </FinderState>
  )
}
