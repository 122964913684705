module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/usr/src/app/www/src/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"266466884533291"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
