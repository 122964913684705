import React, { Component } from 'react';

import('src/sass/setup.scss');

class index extends Component {
	render() {
		return (
			<div>{this.props.children}</div>
		);
	}
}

export default index;